import React from 'react'
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';





const LandingPageWeSurvey = () => {
    return (
        <>

        <Helmet>
        <title>WeSurvey</title>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        </Helmet>

        
        <div className="w3-top">
            <div className="w3-bar w3-white w3-wide w3-padding w3-card">
                <a href="#home" className="w3-bar-item w3-button" >
                    <img src={require('src/pages/landingpages/wesurvey/Logo-Wesurvey.png')} width="auto" height="40" className="d-inline-block align-center" />
                </a>
                    
                
                <div className="w3-right w3-hide-small">
                    <a href="#why" className="w3-bar-item w3-button">Mengapa WeSurvey?</a>
                    <a href="#feature" className="w3-bar-item w3-button">Fitur</a>
                    <a href="#clients" className="w3-bar-item w3-button">Klien Kami</a>
                    <a href="/login" className="w3-bar-item w3-button" style={{backgroundColor: "hsl(0, 61%, 39%)", color:"white"}}>Coba Sekarang</a>
                </div>
            </div>
        </div>

        <header className="w3-container" style={{ paddingTop: 128, paddingBottom: 38, paddingRight: 16, paddingLeft: 16, color: "white", backgroundColor: "hsl(0, 61%, 39%)" }}>
                <div className="w3-content">
                    <div className="w3-twothird">

                            <h1 className="w3-margin w3-jumbo d-none d-sm-block">WeSurvey</h1>
                            <h1 className="w3-margin w3-jumbo d-none d-sm-block">Solusi Terbaik untuk Survei Online Anda</h1>
                            <h1 className="w3-margin d-block d-sm-none"><strong>WeSurvey</strong></h1>
                            <h1 className="w3-margin d-block d-sm-none"><strong>Solusi Terbaik untuk Survei Online Anda</strong></h1>
                            
                            <p className="w3-xlarge">WeSurvey membantu Anda membuat survei yang terstruktur, menganalisis hasil dengan mudah, dan mendapatkan insight untuk pengambilan keputusan yang lebih baik.</p>
                            <a href="/login" className="btn btn-warning btn-lg active" role="button" aria-pressed="true">Mulai Sekarang</a>
                    
                    </div>

                    <div class="w3-third w3-center">
                            <img src={require('./Survey Illutration.png')} height='500'></img>
                    </div>
                </div>
        </header>
        

        <div className="w3-content w3-padding">

            <div className="w3-container w3-padding-32" id="why">
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Mengapa WeSurvey?</h3>
            </div>

            <div className="w3-row-padding">
                <div className="w3-col l4 m6 w3-margin-bottom">
                
                    <div className="w3-topleft w3-black w3-padding">Fleksibel dan Customizable</div>
                    <p>Sesuaikan survei Anda sesuai dengan berbagai jenis pertanyaan</p>
            
                </div>

                <div className="w3-col l4 m6 w3-margin-bottom">
                
                    <div className="w3-topleft w3-black w3-padding">Responsif dan Mobile-Friendly</div>
                    <p>Akses survei dari mana saja dan kapan saja melalui perangkat mobile</p>
                
                </div>

                <div className="w3-col l4 m6 w3-margin-bottom">
                
                    <div className="w3-topleft w3-black w3-padding">Dukungan Pelanggan</div>
                    <p>Tim support yang siap membantu jika Anda memiliki pertanyaan atau masalah</p>
                
                </div>
            </div>

            


            <div className="w3-container w3-padding-32" id="feature">
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Fitur</h3>
                <ul>
                    <li>Form survei dapat dikustomisasi sesuai kebutuhan</li>
                    <li>Data hasil survei yang lengkap dan mudah dipahami, disajikan dalam data file Excel dan PDF yang dapat diunduh</li>
                    <li>Hasil survei real time, dapat dipantau kapan pun dan di mana pun</li>
                    <li>Survei dapat disi kapan pun dan di mana pun</li>
                    <li>Manajemen pengguna yang dapat disesuaikan (admin,supervisor,enumerator,responden)</li>
                </ul>
            </div>

            


            <div className="w3-container w3-padding-32" id="clients">
                <h3 className="w3-border-bottom w3-border-light-grey w3-padding-16">Klien Kami</h3>

                <div className="w3-row-padding">

                    <div className="w3-col l2 m3">
                        
                    </div>

                    <div className="w3-col l2 m3">
                        
                    </div>

                    <div className="w3-col l2 m3">
                        
                    </div>

                    <div className="w3-col l2 m3">
                        
                    </div>

                    <div className="w3-col l2 m3">
                        
                    </div>

                    <div class="container">
                        <div class="row align-center">
                            <div class="col-sm align-center">
                            <a href="https://www.kpk.go.id" className="w3-bar-item w3-button" >
                            <img src={require('src/pages/landingpages/wesurvey/KPK.png')} width="auto" height="60" className="d-inline-block align-center" />
                            </a>
                            </div>

                            <div class="col-sm align-center">
                            <a href="https://www.perpusnas.go.id" className="w3-bar-item w3-button" >
                            <img src={require('src/pages/landingpages/wesurvey/Perpusnas.png')} width="auto" height="60" className="d-inline-block align-center" />
                            </a>
                            </div>

                            <div class="col-sm align-center">
                            <a href="https://www.indekstat.com" className="w3-bar-item w3-button" >
                            <img src={require('src/pages/landingpages/wesurvey/Indekstat.png')} width="auto" height="30" className="d-inline-block align-center" />
                            </a>
                            </div>

                            <div class="col-sm align-center">
                            <a href="https://www.kemendesa.com" className="w3-bar-item w3-button" >
                            <img src={require('src/pages/landingpages/wesurvey/Kemendes.png')} width="auto" height="60" className="d-inline-block align-center" />
                            </a>
                            </div>

                            <div class="col-sm">
                            <a href="https://www.bkpm.com" className="w3-bar-item w3-button" >
                            <img src={require('src/pages/landingpages/wesurvey/BKPM.png')} width="auto" height="60" className="d-inline-block align-center" />
                            </a>
                            </div>


                        </div>
                    </div>

                </div>
            </div>

        </div>



        <footer className="w3-center w3-black w3-padding-16">
        <p>Powered by <a href="https://www.w3schools.com/w3css/default.asp" title="W3.CSS" target="_blank" className="w3-hover-text-green">w3.css</a></p>
        </footer>   

        </>

    )

}

export default LandingPageWeSurvey