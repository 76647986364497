import React from 'react'
import {Helmet} from "react-helmet";
import AggregatedTable from './AgregatedTable';
import Bar from './Bar';
import Card from './Card';


const DashboardAPJII = () => {
    const inputForKorporasi = {
        surveyId: '42',
        questionId: '255',
      };

      const inputForKorporasiFiltered = {
        surveyId: '42',
        questionId: '255',
        filters:["P6"],
      };
    
      const inputForMarketProfile = {
        surveyId: '42',
        questionId: '290',
      }

    return (
        <>
            <Helmet>
                <title>APJII</title>
                <meta name="APJII" content="Helmet application" />
            </Helmet>
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
            

            <nav className="navbar navbar-expand-lg bg-body-primary">
                <div className="container-fluid">
                    <a className="navbar-brand">
                        <div className="mx-100">
                            <img src={require('src/pages/dashboardpages/APJII/logo APJII.png')}  alt="Logo APJII"  width='175' className="d-inline-block align-center"/>
                        </div>
                    </a>

                    <a className="navbar-brand">
                        <div className="mx-100">
                            <img src="https://i.ibb.co/LC2jdNB/Logo-Indekstat-removebg-preview.png" alt="Logo-Indekstat" width="auto" height="30" className="d-inline-block align-center" />
                        </div>
                    </a>
                </div>
            </nav>

            <body>

                <header className="w3-container w3-center" style={{ paddingTop: 128, paddingBottom: 128, paddingRight: 56, paddingLeft: 56, color: "hsla(78, 51%, 90%, 1)", backgroundColor: "hsla(240, 41%, 33%, 1)" }}>
                    <h1 className="w3-margin w3-jumbo d-none d-sm-block">Asosiasi Penyelenggara Jasa Internet Indonesia</h1>
                    <h1 className="w3-margin d-block d-sm-none"><strong>Asosiasi Penyelenggara Jasa Internet Indonesia</strong></h1>
                </header>

                <div className="w3-row-padding w3-padding-64 w3-container">

                    <div className="col-lg-12">
                                <ul className="nav nav-tabs  nav-tabs-custom nav-justified justify-content-center faq-tab-box" id="pills-tab" role="tablist" style={{navLinkActiveColor:'hsla(120, 100%, 75%, 0.7)'}}>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-panduan-tab" data-bs-toggle="pill" data-bs-target="#pills-panduan" type="button" role="tab" aria-controls="pills-panduan" aria-selected="true">
                                            <strong>
                                                <div style={{fontSize:24}}>Survei Internet di Korporasi</div>
                                            </strong>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-faq-tab" data-bs-toggle="pill" data-bs-target="#pills-faq" type="button" role="tab" aria-controls="pills-faq" aria-selected="false">
                                            <strong>
                                            <div style={{fontSize:24}}>Survei Market Profile ISP</div>
                                            </strong>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        

                        <div className="tab-content pt-3" id="pills-tabContent">
                            <div className="tab-pane fade active show" id="pills-panduan" role="tabpanel" aria-labelledby="pills-panduan">
                                <div className="container">
                                    
                                <div className="w3-content">
                                    <div className="w3-twothird">
                                        <h3><strong>Perolehan Total</strong></h3>
                                        <Card input={inputForKorporasi}/>
                                        <h3><strong>Perolehan Per Sektor</strong></h3>
                                        <AggregatedTable input={inputForKorporasiFiltered}/>
                                        <h3><strong>Perolehan Per Hari</strong></h3>
                                        <Bar input={inputForKorporasi}/>
                                    </div>

                                    <div className="col-sm content-bg d-none d-sm-block" style={{minHeight: 595}}>
                                        <i class="fa fa-wifi w3-padding-64" style={{ paddingLeft: 56, fontSize: 200, color: 'hsla(155, 67%, 45%, 1)' }}></i>
                                    </div>
                                </div>

                                </div>
                            </div>
                        </div>

                        <div className="tab-content pt-3" id="pills-tabContent">
                            <div className="tab-pane fade" id="pills-faq" role="tabpanel" aria-labelledby="pills-faq">
                                <div className="container">
                                <div className="w3-content">
                                    <div className="w3-twothird">
                                        <h3><strong>Perolehan Total</strong></h3>
                                        <Card input={inputForMarketProfile}/>
                                        <h3><strong>Perolehan Per Hari</strong></h3>
                                        <Bar input={inputForMarketProfile}/>
                                    </div>

                                    <div className="col-sm content-bg d-none d-sm-block" style={{minHeight: 595}}>
                                        <i class="fa fa-cloud w3-padding-64" style={{ paddingLeft: 56, fontSize: 200, color: 'hsla(155, 67%, 45%, 1)' }}></i>
                                    </div>
                                </div>

                                </div>
                            </div>
                        </div>

                    
                </div>

                



               


                <div className="w3-container w3-black w3-center w3-opacity w3-padding-64">
                    <h1>Tentang <a href="https://www.indekstat.com" target="_blank">APJII</a></h1>
                </div>

                <footer className="w3-container w3-padding-64 w3-center w3-opacity">
                    <p>Powered by <a href="https://www.indekstat.com" target="_blank">Indekstat</a></p>
                </footer>


            </body>
        </>

    )

}

export default DashboardAPJII